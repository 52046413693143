html, body, #root {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  align-items: center;
  background: #f1f5ff;
  color: #081226;
  display: flex;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  flex-direction: column;
  margin: 0;
  width: 100%;
}

* {
  box-sizing: border-box;
  font-family: 'hk_groteskmedium';
}

.Whiskey {
  background: #c5946b;
}

.Tequila {
  background: #e5cf0e;
}

.Vodka {
  background: #bbceeb;
}

.Gin {
  background: #f9d5ff;
}

.Rum {
  background: #e14207;
}

.Mezcal {
  background: #6f6c6c;
}

.Brandy {
  background: #db3939;
}

.Liquer {
  background: #80dab5;
}

textarea {
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #061B33;
  color: #061833;
  font-size: 1rem;
  line-height: 1.4rem;
  padding: 7px 5px;
}

button {
  line-height: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'butlerbold';
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

main {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: #0a1831;
  text-decoration: none;
}

@font-face {
  font-family: 'hk_groteskbold';
  src: url('./assets/fonts/hkgrotesk-bold-webfont.woff2') format('woff2'),
       url('./assets/fonts/hkgrotesk-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'hk_groteskmedium';
  src: url('./assets/fonts/hkgrotesk-medium-webfont.woff2') format('woff2'),
       url('./assets/fonts/hkgrotesk-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'hk_groteskregular';
  src: url('./assets/fonts/hkgrotesk-regular-webfont.woff2') format('woff2'),
       url('./assets/fonts/hkgrotesk-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'butlerbold';
  src: url('./assets/fonts/butler_bold-webfont.woff2') format('woff2'),
       url('./assets/fonts/butler_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'hk_groteskbold';
  src: url('./assets/fonts/hkgrotesk-bold-webfont.woff2') format('woff2'),
       url('./assets/fonts/hkgrotesk-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'hk_groteskmedium';
  src: url('./assets/fonts/hkgrotesk-medium-webfont.woff2') format('woff2'),
       url('./assets/fonts/hkgrotesk-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'hk_groteskregular';
  src: url('./assets/fonts/hkgrotesk-regular-webfont.woff2') format('woff2'),
       url('./assets/fonts/hkgrotesk-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'butlerbold';
  src: url('./assets/fonts/butler_bold-webfont.woff2') format('woff2'),
       url('./assets/fonts/butler_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
